<template>
  <button
    type="button"
    :disabled="disabled"
    class="fgh-button lg fgh-button--primary-outline-grey btn-social-login"
    @click.prevent="logInWithFacebook"
  >
    <vsvg-icon
      data="@assetsIcon/social-fb-icon.svg"
      scale="1"
      original
    />
    Login with Facebook
  </button>
</template>

<script>
export default {
  name: 'FacebookLoginButton',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    await this.loadFacebookSDK(document, "script", "facebook-jssdk")
    await this.initFacebook()
  },

  methods: {
    async logInWithFacebook() {
      window.FB.login((response) => {
        if (response && response.authResponse) {
          this.$emit('success', response.authResponse)
        } else {
          this.$emit('error', response)
        }
      })

      return false
    },

    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          cookie: true,
          version: 'v13.0'
        })
      }
    },

    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    }
  }
};
</script>
